import {
  CartViewed,
  CheckoutStarted,
  CheckoutStepCompleted,
  CouponApplied,
  CouponDenied,
  CouponEntered,
  CouponRemoved,
  CtaClicked,
  InformationEntered,
  LogIn,
  LogInFormDisplayed,
  MenuInteraction,
  MessageShown,
  NewRegistration,
  OrderCompleted,
  ProductAdded,
  ProductClickedQuickLook,
  ProductMoved,
  ProductRemoved,
  ProductsSearched,
  PromotionClicked,
  PromotionViewed,
  RegistrationFormDisplayed,
} from '@/generated/analytics'
import { getCookieByName, getOptionValueName, getProductFormatBC, optionValue } from '@/utils/helpers'
import { AnalyticsDraft, AnalyticsUserData } from './interfaces'

import type { TrackingProps } from '@/components/Tracking/TrackingProvider/TrackingProvider'
import { OptionValue } from '@/domains/core/storefront/generated'
import { Maybe } from '@/generated/graphql/graphql'
import { createAnalyticsUrl, typewriter } from '@/modules/core/contexts/CatalogAnalytics/hooks'
import { trackConversionToImpact } from '@/modules/core/services/impact'
import { ReviewProps } from '@/services/storefront'
import { Drafts, PartialDraft } from '@/services/storefront/drafts/drafts.types'
import { genericAnalyticsEvent } from './integrations'

const BASE_PROPERTIES = {
  selection: 'paper',
}

function trackingString(text?: string) {
  if (!text) return ''
  return text.replace(/\s/g, '_').toLowerCase()
}

function createAnalyticsUserData(): AnalyticsUserData {
  const userId = getCookieByName(window.document?.cookie, 'ajs_user_id') || 'unidentified'
  const anonymousId = getCookieByName(window.document?.cookie, 'ajs_anonymous_id') || 'unidentified'
  return {
    userId: userId.replace(/["]/g, '') ?? '',
    anonymousId: anonymousId.replace(/["]/g, '') ?? '',
  }
}

function trackCartViewed(eventData: TrackingProps & CartViewed) {
  const data = {
    ...eventData,
  }
  typewriter.cartViewed(data)
}

function trackCheckoutStarted(eventData: TrackingProps & CheckoutStarted) {
  const data = {
    ...eventData,
  }
  typewriter.checkoutStarted(data)
}

function trackLogIn(eventData?: TrackingProps & LogIn) {
  const data = {
    selection: 'save draft',
    ...eventData,
  }
  typewriter.logIn(data)
}

function trackLogInFormDisplayed(eventData?: TrackingProps & LogInFormDisplayed) {
  const data = {
    ...eventData,
  }
  typewriter.logInFormDisplayed(data)
}

function trackNewRegistration(eventData?: TrackingProps & NewRegistration) {
  const data = {
    selection: 'save draft',
    ...eventData,
  }
  typewriter.newRegistration(data)
}

function trackRegistrationFormDisplayed(eventData?: TrackingProps & RegistrationFormDisplayed) {
  const data = {
    ...eventData,
  }
  typewriter.registrationFormDisplayed(data)
}

function trackAddPlainEnvelopes(eventData: TrackingProps & Omit<ProductAdded, 'price' | 'category' | 'platform'>) {
  const data = {
    category: 'Plain Wedding Invitations Envelopes',
    price: 0,
    ...eventData,
  }
  typewriter.productAdded(data)
}

function trackCartAddPlainEnvelopes(eventData: TrackingProps & Omit<ProductAdded, 'price' | 'platform'>) {
  const data = {
    price: 0,
    location: 'cart',
    ...eventData,
  }
  typewriter.productAdded(data)
}

function trackMovetoCartFromDrafts(eventData: TrackingProps & AnalyticsDraft) {
  const data = {
    source: 'drafts',
    ...eventData,
  }
  // @ts-expect-error: ignore the current segment schema while waiting for it to be updated by the data team.
  typewriter.productAdded(data)
}

function trackProductAdded(eventData: TrackingProps & ProductAdded) {
  typewriter.productAdded(eventData)
}

function trackProductMoved(eventData: TrackingProps & ProductMoved) {
  const data = {
    destination: 'drafts',
    source: 'cart',
    ...eventData,
  }
  typewriter.productMoved(data)
}

function trackProductDelete(eventData: TrackingProps & ProductRemoved) {
  const data = {
    location: 'cart',
    ...eventData,
  }
  typewriter.productRemoved(data)
}

const trackPromotionViewed = (eventData: TrackingProps & PromotionViewed) => {
  const data = {
    ...eventData,
  }
  typewriter.promotionViewed(data)
}

const trackProductsSearched = (eventData: TrackingProps & ProductsSearched, callback?: () => void) => {
  const data = {
    ...eventData,
    ...BASE_PROPERTIES,
  }

  return new Promise((resolve) => {
    typewriter.productsSearched(data, undefined, callback)
    resolve(true)
  })
}

const trackPromotionClicked = (eventData: TrackingProps & PromotionClicked, callback?: () => void) => {
  const data = {
    ...eventData,
  }
  return new Promise((resolve) => {
    typewriter.promotionClicked(data, undefined, callback)
    resolve(true)
  })
}

function trackCheckoutStepCompleted(eventData: TrackingProps & CheckoutStepCompleted) {
  const data = {
    ...eventData,
  }
  typewriter.checkoutStepCompleted(data)
}

function trackShippingInfoEntered(eventData: TrackingProps & InformationEntered) {
  const data = {
    ...eventData,
  }
  typewriter.informationEntered(data)
}

function trackCouponApplied(eventData: TrackingProps & CouponApplied) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriter.couponApplied(data)
}

function trackCouponDenied(eventData: TrackingProps & CouponDenied) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriter.couponDenied(data)
}

function trackCouponRemoved(eventData: TrackingProps & CouponRemoved) {
  const data = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriter.couponRemoved(data)
}

const trackOrderCompleted = (rawData: any, email: string, customerId: string, customerStatus: string) => {
  const { affiliation, memberId, platform, product, ...eventData } = rawData
  const revenue = +(+eventData?.subtotal_ex_tax)?.toFixed(2)
  const discount = +(+eventData?.coupon_discount)?.toFixed(2)
  const subtotal = revenue - discount
  const message: OrderCompleted = {
    products: eventData?.products
      ?.map((product: any, index: number) => {
        try {
          const metaOption = product.product_options.find((opt: any) => /metadata/i.test(opt.display_name))
          const meta = JSON.parse(metaOption.display_value)
          const optionValues = meta?.optionValues
          const url = createAnalyticsUrl(meta?.slug)
          return {
            product_id: meta?.catalogProductId,
            sku: meta?.variationId,
            category: meta?.productTypeName || '',
            name: meta?.productName,
            price: +(+product.total_ex_tax).toFixed(2),
            quantity: product.quantity,
            coupon: product?.applied_discounts?.[0]?.code || '',
            position: index + 1,
            url,
            image_url: meta?.productPhotos?.hero,
            sample: meta?.isSample,
            variant: createAnalyticsVariant(optionValues, meta.productCode),
          }
        } catch (e) {
          return null
        }
      })
      .filter(Boolean),
    coupon: eventData?.coupons?.[0]?.code || '',
    currency: eventData?.currency_code,
    order_id: eventData?.id?.toString(),
    revenue,
    discount,
    //@ts-expect-error: ignore the current segment schema while waiting for it to be updated by the data team.
    subtotal,
    shipping: +(+eventData?.shipping_cost_ex_tax)?.toFixed(2),
    tax: +(+eventData?.subtotal_tax)?.toFixed(2),
    total: +(+eventData?.total_inc_tax)?.toFixed(2),
  }
  const data = {
    ...message,
    email: email,
    affiliation: affiliation as string,
    memberId: memberId as string | null | undefined,
    platform: platform as string,
    product: product as string,
  }

  typewriter.orderCompleted(data)
  trackConversionToImpact(data, customerId, customerStatus)
}

function trackMenuInteraction(eventData: TrackingProps & MenuInteraction) {
  const data = {
    source: 'subnav',
    ...eventData,
  }
  typewriter.menuInteraction(data)
}

async function trackCtaClicked(eventData: TrackingProps & CtaClicked) {
  const eventPayload = {
    ...eventData,
  }
  typewriter.ctaClicked(eventPayload)
}

function trackCtaClickedReview(eventData: TrackingProps & CtaClicked) {
  const eventPayload = {
    element_clicked: 'link',
    location: 'review',
    reason: 'upsell',
    ...eventData,
  }

  typewriter.ctaClicked(eventPayload)
}

function trackMessageShown(eventData: TrackingProps & MessageShown) {
  const eventPayload = {
    type: 'tooltip',
    ...eventData,
  }

  typewriter.messageShown(eventPayload)
}

function trackQuickLook(eventData: TrackingProps & ProductClickedQuickLook) {
  const eventPayload = {
    ...eventData,
  }

  typewriter.productClickedQuickLook(eventPayload)
}

function trackCouponEntered(eventData: TrackingProps & CouponEntered) {
  const eventPayload = {
    ...eventData,
    coupon_name: eventData.coupon,
  }
  typewriter.couponEntered(eventPayload)
}

function trackDraftDeleted(trackingData: TrackingProps, draft: PartialDraft) {
  const { portfolioItem } = draft

  const eventPayload = {
    ...trackingData,
    name: draft.variation.product.name,
    draft_id: draft.id,
    price: draft.price,
    quantity: draft.quantity,
    category: draft.variation.product.type.name,
    url: `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}${process.env.APP_DRAFTS_PATH}`,
    portfolioItemId: portfolioItem?.id,
    sample: draft.variation?.isSample,
    product_id: draft.variation?.catalogProductId,
    sku: draft.variation?.suiteCode,
    variant: {
      color: draft.variation?.colorThemeCode,
      foil_color: draft.variation?.foilCode,
      format: draft.variation?.productCode,
      material: draft.variation?.paperTypeCode,
      printing_options: draft.variation?.envelopePrintingOptionCode,
    },
  }

  //@ts-expect-error: ignore the current segment schema while waiting for it to be updated by the data team.
  typewriter.draftDeleted(eventPayload)
}

function trackCustomizationReviewInitialized(reviewProps: ReviewProps, trackingProps: TrackingProps) {
  const selectSegmentVariant = (reviewProps: ReviewProps) => {
    enum ProductOptionId {
      ColorTheme = 'colorThemeCode',
      FoilTheme = 'foilCode',
      PaperType = 'paperTypeCode',
      EnvelopePrintingOption = 'envelopePrintingOptionCode',
    }

    const selectProductOption = (
      productOptionId: ProductOptionId,
      optionValues: Maybe<Array<OptionValue>> | undefined
    ): OptionValue | undefined => optionValues?.find((optionValue) => optionValue.optionId === <string>productOptionId)

    return {
      color: selectProductOption(ProductOptionId.ColorTheme, reviewProps.variation.optionValues)?.name,
      foil_color: selectProductOption(ProductOptionId.FoilTheme, reviewProps.variation.optionValues)?.name,
      format: reviewProps.product.type.name,
      material: selectProductOption(ProductOptionId.PaperType, reviewProps.variation.optionValues)?.name,
      printing_options: selectProductOption(ProductOptionId.EnvelopePrintingOption, reviewProps.variation.optionValues)
        ?.valueId,
    }
  }

  typewriter.customizationReviewInitialized({
    category: reviewProps.product.type.name,
    location: 'review',
    name: reviewProps.name,
    portfolio_item_id: reviewProps.product.portfolioItemId,
    price: Number(reviewProps.price?.basePrice),
    product_id: reviewProps.variation.id,
    quantity: reviewProps.quantity,
    sample: reviewProps.variation.isSample,
    sku: reviewProps.product.type.sku,
    url: `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}/${reviewProps.product.slug}`,
    //@ts-expect-error: ignore the current segment schema while waiting for it to be updated by the data team.
    variant: selectSegmentVariant(reviewProps),
    ...trackingProps,
  })
}

function trackDraftListViewed(trackingProps: TrackingProps, drafts: Drafts) {
  const draftsTracking = drafts.map((draft, index) => {
    return {
      image_url: createHeroImageUrl(draft.variation.variationImages),
      name: draft.variation.product.name,
      position: index + 1,
      price: Number(draft.price),
      product_id: draft.variation.catalogProductId,
      quantity: draft.quantity,
      sample: draft.variation.isSample,
      sku: draft.variation.id,
      url: createAnalyticsUrl(draft.variation.product.slug),
      variant: createAnalyticsVariant(draft.variation.optionValues ?? [], draft.variation.productCode),
    }
  })
  typewriter.draftListViewed({
    ...trackingProps,
    list_id: 'drafts',
    drafts: draftsTracking,
  })
}

const createHeroImageUrl = (variationImages: { imageUrl: string; tag: string }[]): string => {
  const heroImage = variationImages?.find((image) => {
    return image.tag === 'hero'
  })
  return heroImage ? `${process.env.APP_IMAGE_HOST}/master${heroImage.imageUrl}` : ''
}

const createAnalyticsVariant = (optionValues: optionValue[], productCode: string) => {
  return [
    {
      color: getOptionValueName(optionValues, 'colorThemeCode') || '',
      format: getProductFormatBC(productCode),
      material: getOptionValueName(optionValues, 'paperTypeCode') || '',
      printing_options: getOptionValueName(optionValues, 'envelopePrintingOptionCode'),
      foil_color: getOptionValueName(optionValues, 'foilCode'),
      flap_type: getOptionValueName(optionValues, 'flapTypeCode'),
      paper_color: getOptionValueName(optionValues, 'paperColorCode'),
    },
  ]
}

function isMobileUserAgent(userAgent = ''): boolean {
  // Check if 'Mobi' is present in the user agent
  return /Mobi/.test(userAgent)
}

export {
  createAnalyticsUserData,
  createAnalyticsVariant,
  createHeroImageUrl,
  genericAnalyticsEvent,
  isMobileUserAgent,
  trackAddPlainEnvelopes,
  trackCartAddPlainEnvelopes,
  trackCartViewed,
  trackCheckoutStarted,
  trackCheckoutStepCompleted,
  trackCouponApplied,
  trackCouponDenied,
  trackCouponEntered,
  trackCouponRemoved,
  trackCtaClicked,
  trackCtaClickedReview,
  trackCustomizationReviewInitialized,
  trackDraftDeleted,
  trackDraftListViewed,
  trackingString,
  trackLogIn,
  trackLogInFormDisplayed,
  trackMenuInteraction,
  trackMessageShown,
  trackMovetoCartFromDrafts,
  trackNewRegistration,
  trackOrderCompleted,
  trackProductAdded,
  trackProductDelete,
  trackProductMoved,
  trackProductsSearched,
  trackPromotionClicked,
  trackPromotionViewed,
  trackQuickLook,
  trackRegistrationFormDisplayed,
  trackShippingInfoEntered,
}
