import { TrackingProvider } from '@/components/Tracking'
import { FeatureFlags } from '@/services/unleash.service'
import { DeviceMode } from '@/utils/analytics/interfaces'
import { UserProfile } from '@/wwBrand/modules/core/services/auth'
import { ReactNode } from 'react'
import { FeatureToggleProvider } from '../Feature'

type Props = {
  children: ReactNode
  features: FeatureFlags
  platform: DeviceMode
  user: UserProfile | null | undefined
}

const AppProviders = ({ children, features, platform = 'desktop web', user }: Props) => {
  const baseTracking = {
    memberId: user ? user.id : null,
    platform: platform,
    product: 'paper',
  }

  return (
    <TrackingProvider {...baseTracking}>
      <FeatureToggleProvider features={features}>{children}</FeatureToggleProvider>
    </TrackingProvider>
  )
}

export { AppProviders }
