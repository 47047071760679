import { useRouter } from 'next/router'
import { ComponentType, createContext, PropsWithChildren, useCallback, useContext, useState } from 'react'
import { SortOption, sortOptions } from './utils'

export type SortContext = {
  selectedSortOption: SortOption
  selectSortOption: (option: SortOption) => void
  setDefaultSort: () => void
}

const SortContext = createContext<SortContext | undefined>(undefined)

export const SortProvider: ComponentType<PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter()
  const querySortOption = router?.query?.sort && sortOptions.find((option) => option.value === router.query.sort)
  const initSortOption = querySortOption || sortOptions[0]
  const [selectedSortOption, selectSortOption] = useState<SortOption>(initSortOption)
  const setDefaultSort = useCallback(() => {
    selectSortOption(sortOptions[0])
  }, [])

  return (
    <SortContext.Provider value={{ selectedSortOption, selectSortOption, setDefaultSort }}>
      {children}
    </SortContext.Provider>
  )
}

export const useSort = () => {
  const context = useContext(SortContext)

  if (!context) {
    throw new Error('useSort must be used within a SortProvider')
  }

  return context
}
