import { Product, Variation } from '@/services/algolia'
import { CatalogAnalyticsData, CatalogAnalyticsProviderProps } from '@/utils/analytics/interfaces'
import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'

const mergeSampleFields = (product: Product, variation: Variation) => ({
  ...product,
  variationId: variation.id,
  paperType: variation?.paperType?.name,
  foilId: variation?.foil?.id,
})

const CatalogAnalyticsContext = createContext<CatalogAnalyticsData | undefined>(undefined)

const CatalogAnalyticsProvider: ComponentType<PropsWithChildren<CatalogAnalyticsProviderProps>> = ({
  children,
  search,
  category: cat,
}) => {
  const location = search ? 'search results' : 'category list'

  const category = !cat ? 'all' : cat
  const data = {
    location,
    category,
  }

  return <CatalogAnalyticsContext.Provider value={data}>{children}</CatalogAnalyticsContext.Provider>
}

const useCatalogAnalytics = () => {
  const context = useContext(CatalogAnalyticsContext)

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a CatalogAnalyticsProvider')
  }

  return context
}

export { useTrackProductsFiltered, useTrackProductsViewed } from './hooks'
export { CatalogAnalyticsProvider, mergeSampleFields, useCatalogAnalytics }
