import type { Variants } from '@/lib/flipper'
import { ExperimentService, FlipperProvider, YOUR_DRAFTS_LANDING_PAGE_EXPERIMENT_ID } from '@/lib/flipper'
import type { ExperimentInfo } from '@/lib/flipper/flipper.types'
import Honeybadger from '@/modules/core/services/honeybadger.service'
import { AppProviders } from '@/modules/shared'
import type { FeatureFlags } from '@/services/unleash.service'
import { Layouts, type DefaultPageProps, type LayoutProps } from '@/utils/pages'
import { HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import type { AppContext, AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { UserProvider } from '@/components/User'
import { initializeTracking } from '@/modules/core/services/analytics/tracker'
import { CONFIG, isTenant } from '@/utils/config'

import '@/styles/global.scss'
import '@xo-union/tk-ui-essentials'

import { MembershipProvider } from '@/modules/core/contexts/membershipAuth'

import { isMobileUserAgent } from '@/utils/analytics/helpers'
import { DeviceMode } from '@/utils/analytics/interfaces'
import Head from 'next/head'

const WebviewTag = dynamic(() => import('@/modules/core/components/WebviewTag'), { ssr: true })
const Web = dynamic(() => import('@/ui/_extracted/Web').then((m) => m.Web), { ssr: true })
const Webview = dynamic(() => import('@/ui/_extracted/Webview').then((m) => m.Webview), { ssr: true })
const TKVariables = dynamic(() => import('@/styles/Variables/TKVariables'), { ssr: true })
const WWVariables = dynamic(() => import('@/styles/Variables/WWVariables'), { ssr: true })
const CosmoBox = dynamic(() => import('@/lib/cosmobox').then((m) => m.CosmoBox))
const PageNotFoundComponent = dynamic(
  () =>
    import('@/modules/core/components/PageNotFound').then((mod) => {
      return isTenant('tk') ? mod.default : mod.PageNotFoundWW
    }),
  { ssr: true }
)

const appEnv = process.env.APP_ENV?.toUpperCase() || 'DEVELOPMENT'

interface PartialPageProps {
  pageProps: {
    deviceMode: DeviceMode
    layout: LayoutProps
    isApp: boolean
    shouldReloadPage?: boolean
    variants?: Variants
    cookies: string
    features: FeatureFlags
    cosmoboxProps: {
      brand: string
      country: string
    }
  }
}

if (process.env.APP_API_MOCKING === 'enabled') {
  require('../mocks')
}

const GlobalVariables = isTenant('tk') ? TKVariables : WWVariables

declare global {
  interface Window {
    fbq: any
  }
}

const BaseApp = ({ Component, pageProps }: AppProps<DefaultPageProps>) => {
  const {
    deviceMode,
    layout,
    error,
    seoTags,
    userProfile,
    isApp,
    shouldReloadPage,
    variants,
    features,
    cookies,
  }: DefaultPageProps = pageProps

  const { replace, asPath } = useRouter()

  // Enforce the right tracking mechanism
  // Change the "enforce" value to "mobile app ios" or "mobile app android" for mobile app issues :)
  initializeTracking({
    enforce: deviceMode,
    log: /(development|foundation-staging|qa)/i.test(appEnv),
  })

  useEffect(() => {
    if (shouldReloadPage) {
      replace(asPath)
    }
  }, [asPath, replace, shouldReloadPage])

  useEffect(() => {
    if (/(development)/i.test(appEnv)) {
      globalThis.window.fbq = () => console.warn('fbq is being mocked')
    }
  }, [])

  if (error && !shouldReloadPage) {
    return <PageNotFoundComponent userProfile={userProfile} />
  }

  const Layout = layout?.name === Layouts.WebView ? Webview : Web

  return (
    <MembershipProvider cookies={cookies}>
      <HoneybadgerErrorBoundary honeybadger={Honeybadger}>
        <Head>
          {layout?.snippets?.commerceDataSnippet && (
            <WebviewTag webviewDataJson={layout?.snippets?.commerceDataSnippet} />
          )}
        </Head>
        <AppProviders features={features} platform={deviceMode} user={userProfile}>
          <CosmoBox />
          <FlipperProvider variants={variants}>
            <UserProvider user={userProfile}>
              <GlobalVariables>
                <Layout
                  {...{
                    Component,
                    deviceMode,
                    layout,
                    seoTags,
                    userProfile,
                    isApp,
                    shouldReloadPage,
                    features,
                    pageProps,
                  }}
                />
              </GlobalVariables>
            </UserProvider>
          </FlipperProvider>
        </AppProviders>
      </HoneybadgerErrorBoundary>
    </MembershipProvider>
  )
}

export const partialCommonPageProps = async ({ ctx }: AppContext): Promise<PartialPageProps> => {
  const { req, res } = ctx
  const isServerSide = !!req && !!res
  const experimentService = ExperimentService.forRoot({})
  const cookies = req?.headers.cookie || ''
  const cosmoBoxBrand = CONFIG.tenant.shortName.toUpperCase() || 'TK'

  let getAllFeatures
  let excludedIncludedPromise
  let variants: { [k: string]: ExperimentInfo } | undefined = ExperimentService.cookies()
  let features = {} as FeatureFlags
  let shouldReloadPage = false
  let setCookie = ''
  let layout = undefined
  let deviceMode: DeviceMode = 'desktop web'
  let isApp = false
  if (isServerSide) {
    //Load up all server services and helper functions we ONLY want on the server side.
    const [fullstory, { refreshSession }, { isWeddingWireApp, getLayout, isAndroidApp, isIOSWebview }] =
      await Promise.all([
        import('@/lib/fullstory/fullstory.experiment').then((m) => m.fullstory),
        import('@/modules/core/services/session.service'),
        import('@/utils/pages'),
      ])

    // TODO: move this call later in a middleware layer.
    /**
     * This call should be one of the first calls.
     * Failing to do so can result in
     * definitely causes this on the server Member Not found https://app.honeybadger.io/projects/97863/faults/97975544/01HCHECEXK90TE701BGG20ZK3G?page=0
     * can also potentially cause credentials missing error
     */
    const refresh = await refreshSession(ctx)

    //Calculate which device is loading the page and set the Layout conditions.
    isApp = isWeddingWireApp(req?.headers)

    deviceMode = (function (): DeviceMode {
      if (isIOSWebview(req?.headers)) return 'mobile app ios'
      if (isAndroidApp(req?.headers)) return 'mobile app android'
      if (isMobileUserAgent(req?.headers?.['user-agent'])) return 'mobile web'

      return 'desktop web'
    })()

    if (deviceMode === 'mobile app ios' || deviceMode === 'mobile app android') {
      experimentService.addToSkipList([YOUR_DRAFTS_LANDING_PAGE_EXPERIMENT_ID])
    }

    //Get the feature set available for the user.
    ;[getAllFeatures, excludedIncludedPromise, variants, layout] = await Promise.all([
      import('@/services/unleash.service').then((m) => m.getAllFeatures),
      fullstory.initExperiment(ctx.req?.headers?.cookie || ''),
      experimentService.initialize(ctx.req?.headers, ctx.pathname, ctx.asPath),
      getLayout(req?.headers),
    ])

    const [featuresResolved, { excluded, included }] = await Promise.all([
      getAllFeatures(ctx.req?.headers.cookie),
      excludedIncludedPromise,
    ])
    features = featuresResolved
    experimentService.addToSkipList(excluded)

    //Set cookies
    shouldReloadPage = refresh.shouldReloadPage
    setCookie = refresh.setCookie

    const cookiesToSet = [setCookie, experimentService.variantsCookie, experimentService.anonymousIdCookie].filter(
      Boolean
    )
    if (variants?.[included]) {
      const fs = variants[included]
      cookiesToSet.push(fullstory.setCookie(res, fs.variantName))
    }

    ctx.res?.setHeader('Set-Cookie', cookiesToSet)
  } else if (window?.navigator?.userAgent) {
    if (isMobileUserAgent(window.navigator.userAgent)) {
      deviceMode = 'mobile web'
    } else {
      deviceMode = 'desktop web'
    }
  }

  return {
    pageProps: {
      deviceMode: deviceMode,
      layout: (layout || {}) as any,
      isApp: isApp,
      shouldReloadPage,
      variants,
      cookies,
      features,
      cosmoboxProps: {
        brand: cosmoBoxBrand,
        country: 'US',
      },
    },
  }
}

BaseApp.getInitialProps = partialCommonPageProps

export default BaseApp
