import NextImage, { ImageProps as NextImageProps } from 'next/future/image'
import { useState } from 'react'

import cx from 'classnames'
import styles from './Image.module.scss'

const MEDIA_API_URL = process.env.APP_MEDIA_API as string

export const RESIZE = '~rs'

export const CROP = '~sc'

const DEFAULT_OPTIONS: Pick<MediaAPILoaderOptions, 'crop' | 'quality'> = {
  crop: false,
  quality: 75,
}
/**
 * Image host URL. Currently is s3
 */
const IMAGE_HOST_URL = process.env.APP_IMAGE_HOST as string

const HOST_REGEX = new RegExp(`^${process.env.APP_IMAGE_HOST as string}/\\w+`)

/**
 * Omitted props to be ready for future NextJS updates
 */
type ImageProps = Omit<NextImageProps, 'layout' | 'objectFit' | 'objectPosition'> & { native?: boolean }
/**
 * This component is a wrapper around NextImage
 * It should be used in place of NextImage to avoid coupling
 */
export function Image({ className, ...props }: ImageProps) {
  const [load, setLoad] = useState(false)
  if (props.native) {
    const srcSet = String(props.src && props.src).includes('media-api.xogrp.com') ? String(props.src) : ''
    return (
      <>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={props.src as string}
          alt={`${props.alt}`}
          sizes={props.sizes}
          srcSet={srcSet}
          loading={props?.priority ? 'eager' : 'lazy'}
          decoding="async"
          width={props?.width}
          height={props?.height}
          onLoad={() => setLoad(true)}
          className={cx(styles.image, className, {
            [styles.skeleton]: !load,
          })}
        />
      </>
    )
  }

  return (
    <NextImage
      {...props}
      onLoad={() => setLoad(true)}
      className={cx(styles.image, className, {
        [styles.skeleton]: !load,
      })}
    />
  )
}

function buildMediaAPIOptions(options: MediaAPILoaderOptions) {
  /**
   * !To validate with MEDIA_API docs what quality does.
   */
  const { crop, width, height } = { ...DEFAULT_OPTIONS, ...options }

  const cropOption = crop ? `${CROP}_${width}.${width}` : ''
  // -fit : to validate with MEDIA_API docs what it does.
  const resizeOption = `${RESIZE}_${width}.${height}-fit`
  // const qualityOption = `q_${quality}`
  return `transform${resizeOption}${cropOption}`
}

export type MediaAPILoaderOptions = {
  /**
   * Image width
   */
  width: number | 'w'
  /**
   * Image height
   */
  height: number | 'h'
  /**
   * Image quality
   * @default 75
   */
  quality?: number
  /**
   * @default false
   */
  crop?: boolean
}

/**
 *
 * @param source the URL of the image
 * @param options MediaAPILoaderOptions
 * @returns a valid URL to consume images from Media API
 * @example https://media-api.xogrp.com/images/fetch/https://tkww-paper-designs.s3.amazonaws.com/master/0826k/sdc/image-files/color-theme_wht/hero/eucalyptus-frame-save-the-date-cards.jpg/transform~rs_500.500-fit~sc_500
 */
export function mediaAPILoader(source: string, options?: MediaAPILoaderOptions) {
  const mediaOptions = options ? buildMediaAPIOptions(options) : ''
  const resource = source.replace(MEDIA_API_URL, '')
  const url = `${MEDIA_API_URL}/images/fetch/${resource}`
  return mediaOptions ? `${url}/${mediaOptions}` : url
}

/**
 *
 * @param source the URL of the image
 * @returns full URL with the image host
 */
export function buildImageURL(source: string) {
  return HOST_REGEX.test(source) ? source : `${IMAGE_HOST_URL}/master/${source}`
}
