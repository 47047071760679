import typewriter from '@/generated/analytics'
import { DeviceMode } from '@/utils/analytics/interfaces'

type Data = Record<string, unknown>
type InitializeTracking = { enforce?: DeviceMode; log: boolean }

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

export function initializeTracking(
  { enforce, log }: InitializeTracking = { enforce: 'desktop web', log: false }
): void {
  const { window } = global
  if (!window) return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - ios webview tracking
  if (enforce === 'mobile app ios' || window.webkit?.messageHandlers?.trackAppEvent) {
    setupIOSTracking({ log })
    return
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - android webview tracking
  if (enforce === 'mobile app android' || window?.PlannerAndroid?.trackAppEvent) {
    setupAndroidTracking({ log })
    return
  }
}

const mergeBaseWebviewOptions = (props: Record<string, any>): Record<string, any> => {
  // Allow the native platform to set its own value like: ios or android
  const { platform, ...rest } = props
  return { ...rest, section: 'paper' }
}

function titleCase(text: string) {
  const result = text.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

function setupAndroidTracking({ log } = { log: false }) {
  function track(event: string, properties?: Record<string, unknown>, callback?: () => void) {
    const msg = JSON.stringify(mergeBaseWebviewOptions(properties as Data))
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window?.PlannerAndroid?.trackAppEvent(event, msg)
      if (typeof callback === 'function') callback()
    } catch (e) {
      console.error(e)
    } finally {
      if (log) {
        console.log('Using Android webview tracking')
        console.log('window.PlannerAndroid.trackAppEvent', event, msg)
      }
    }
  }

  patchTypewriter(track)

  window.analytics = {
    ...(window.analytics || {}),
    track: track as SegmentAnalytics.AnalyticsJS['track'],
  }
}

function setupIOSTracking({ log } = { log: false }) {
  function track(event: string, properties?: Record<string, unknown>, callback?: () => void) {
    const msg = event + '+' + JSON.stringify(mergeBaseWebviewOptions(properties as Data))

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.webkit?.messageHandlers?.trackAppEvent.postMessage(msg)
      if (typeof callback === 'function') callback()
    } catch (e) {
      console.error(e)
    } finally {
      if (log) {
        console.log('Using iOS webview tracking')
        console.log('window.webkit.messageHandlers.trackAppEvent.postMessage', msg)
      }
    }
  }

  patchTypewriter(track)

  window.analytics = {
    ...(window.analytics || {}),
    track: track as SegmentAnalytics.AnalyticsJS['track'],
  }
}

function patchTypewriter(
  trackFn: (event: string, properties?: Record<string, unknown>, callback?: () => void) => void
) {
  Object.keys(typewriter)
    .filter((key) => !key.includes('setTypewriterOptions'))
    .forEach((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typewriter[k] = (properties?: Record<string, unknown>, _options: any, callback?: () => void) => {
        trackFn(titleCase(k), properties, callback)
      }
    })
}
