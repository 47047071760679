import { UserProfile } from '@/wwBrand/modules/core/services/auth'
import { ComponentType, createContext, PropsWithChildren } from 'react'

type UserProviderProps = {
  user?: UserProfile | null
  isLoggedIn: boolean
}

export const UserContext = createContext<UserProviderProps | null>(null)

export const UserProvider: ComponentType<PropsWithChildren<Pick<UserProviderProps, 'user'>>> = ({ user, children }) => {
  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
