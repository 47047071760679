export const FORMATS_CODE_MAP: Record<string, string> = {
  AIO: 'All-in-one',
  SDT: 'Petite',
  SDM: 'Magnet',
  SDP: 'Postcard',
}

export const BREAKPOINTS = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1192,
  xxl: 1360,
}
